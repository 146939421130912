import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import { App } from './App';
import { store } from './app/store';
import { AppConfig } from './models/dtos';

declare let _appConfig: AppConfig;

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  ,
  document.getElementById('root')
);

if (!_appConfig) {
  _appConfig = {} as any;
  console.log("bukll");
}

if (process.env.NODE_ENV === "development") {
  _appConfig.apiUrl = "http://localhost:9869";
  console.log("set");
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
