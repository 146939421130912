import React from 'react';
import { Redirect } from 'react-router-dom';

import { store } from '../../app/store';
import { clearAuth } from './authSlice';

const Logout: React.FC = () => {
    store.dispatch(clearAuth());
    return <Redirect to={"/login"} />;
}

export default Logout;
