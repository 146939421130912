import { EuiConfirmModal } from "@elastic/eui";
import React from "react";

type IProps = {
  email: string;
  onYes(): void;
  onNo(): void;
};

const ResendActivationEmailModal: React.FC<IProps> = ({
  email,
  onYes,
  onNo,
}) => {
  let modal = null;
  if (email) {
    modal = (
      <EuiConfirmModal
        title="Confirmation"
        onCancel={onNo}
        onConfirm={onYes}
        cancelButtonText="No"
        confirmButtonText="Yes"
        defaultFocusedButton="confirm"
      >
        <p>
          Send activation email to <strong>{email}</strong>?
        </p>
      </EuiConfirmModal>
    );
  }

  return modal;
};

export default ResendActivationEmailModal;
