import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RootState, store } from '../../app/store';
import { AppConfig } from '../../models/dtos';
import { login } from './authService';
import { setAuth } from './authSlice';
import ForgotPasswordModal from './ForgotPasswordModal';

interface FormValues {
  Email: string;
  Password: string;
}

interface ComponentState {
  errorMessage: string | null;
  isSubmitting: boolean;
}

declare const _appConfig: AppConfig;

const Login = (props: RouteComponentProps) => {

  const activeAuth = useSelector((state: RootState) => state.auth.auth);

  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState<boolean>(false);

  const { register, handleSubmit, formState } = useForm({
    mode: "onChange"
  });

  const initialState: ComponentState = {
    errorMessage: null,
    isSubmitting: false
  }
  const [state, setState] = useState(initialState);

  if (activeAuth?.access_token) {
    let redirectPath = "/candidate-home";
    if (activeAuth?.role == "Admin") {
      redirectPath = "/enrollments";
    }
    console.log(redirectPath);
    return <Redirect to={redirectPath} />;
  }

  const onSubmit = (values: FormValues) => {
    toast.dismiss();
    setState({ ...state, isSubmitting: true });
    login(values.Email, values.Password)
      .then(auth => {
        store.dispatch(setAuth(auth));
        _appConfig.accessToken = auth.access_token;
        setState({ ...state, isSubmitting: false });
      })
      .catch(error => {
        console.log(error);
        toast.error("Invalid email or password.");
        setState({ ...state, errorMessage: "Sign in failed.", isSubmitting: false });
      });
  };

  return (
    <>
      <EuiFlexGroup justifyContent="spaceAround" alignItems="center" style={{ minHeight: "99vh", width: "99%" }}>
        <EuiFlexItem grow={false}>
          <EuiPanel style={{ width: "450px" }} paddingSize="l">
            <EuiTitle size="s">
              <h2>CTCMA Interactive Safety Course</h2>
            </EuiTitle>
            <EuiSpacer />
            <EuiForm component="form" onSubmit={handleSubmit(onSubmit)}>
              <EuiFormRow label="Email">
                <EuiFieldText name="Email"
                  icon="email"
                  inputRef={register({ required: true, minLength: 6 })}
                />
              </EuiFormRow>
              <EuiFormRow label="Password">
                <EuiFieldPassword name="Password"
                  inputRef={register({ required: true, minLength: 6 })} />
              </EuiFormRow>
              <EuiSpacer />
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiButton type="submit" fill disabled={!formState.isValid} isLoading={state.isSubmitting}>
                    Sign In
                </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButtonEmpty onClick={() => setForgotPasswordModalVisible(true)}>
                    Forgot Password?
                </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <ForgotPasswordModal visible={forgotPasswordModalVisible} onClose={() => setForgotPasswordModalVisible(false)} />
    </>
  );
}

export default Login;
