import { useSelector } from 'react-redux';
import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiHeader, EuiHeaderLogo, EuiHeaderSectionItem, EuiHeaderSectionItemButton, EuiLink, EuiPopover } from '@elastic/eui';
import React, { useState } from 'react';

import { RootState } from './store';
import Sidebar from './Sidebar';


const Header: React.FC = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const onUserButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
  const closePopover = () => setIsPopoverOpen(false);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const setSidebarVisibility = (visibility: boolean) => setIsSidebarVisible(visibility);

  const { access_token, email, full_name } = useSelector((state: RootState) => state.auth.auth);

  const breadcrumbs = useSelector((state: RootState) => state.auth.breadcrumbs);

  if (!access_token || !full_name || !email) {
    return null;
  }

  const renderSidebar = (
    <Sidebar isVisible={isSidebarVisible} setVisibility={setSidebarVisibility} />
  );

  const renderButton = (
    <EuiHeaderSectionItemButton
      aria-expanded={isPopoverOpen}
      aria-label="Account menu"
      aria-haspopup="true"
      aria-controls="headerUserMenu"
      onClick={onUserButtonClick}
    >
      <EuiAvatar name={full_name} size="s" />
    </EuiHeaderSectionItemButton>
  );

  const sections = [
    {
      items: [renderSidebar],
      breadcrumbs: breadcrumbs,
    }
  ];

  return (
    <>
      <EuiHeader
        theme="dark"
        position="fixed"
      >
        <EuiHeaderSectionItem>
          <EuiHeaderLogo
            onClick={() => { }}
            iconType="home"
          >CTCMA Interactive Safety Course</EuiHeaderLogo>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <EuiPopover
            id="headerUserMenu"
            ownFocus
            button={renderButton}
            isOpen={isPopoverOpen}
            closePopover={closePopover}>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiAvatar name={full_name} size="xl" />
              </EuiFlexItem>
              <EuiFlexItem style={{ minWidth: "10rem" }}>
                <p>{full_name}</p>
                <br></br>
                <EuiLink href="/#/logout" onClick={closePopover}>
                  Sign out
                </EuiLink>

              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPopover>
        </EuiHeaderSectionItem>
      </EuiHeader>
      {/* <EuiHeader
        position="fixed"
        sections={sections}
      /> */}
    </>

  );
}

export default Header;
