import {
    EuiButton,
    EuiButtonEmpty,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
} from '@elastic/eui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { forgotPassword } from './authService';

interface ForgotPasswordModalProps {
    visible: boolean;
    onClose: () => void;
}

interface ForgotPasswordModalState {
    isSubmitting: boolean;
    submittedSuccessfully: boolean;
}

interface FormValues {
    Email: string;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = (props) => {

    const { register, handleSubmit, formState } = useForm({
        mode: "onSubmit"
    });

    const initialState: ForgotPasswordModalState = {
        isSubmitting: false,
        submittedSuccessfully: false
    }
    const [state, setState] = useState(initialState);

    const closeModal = () => {
        props.onClose();
    }

    const onSubmit = (values: FormValues) => {
        setState({ ...state, isSubmitting: true, submittedSuccessfully: false });
        forgotPassword({ email: values.Email })
            .then(() => {
                setState({ ...state, isSubmitting: false, submittedSuccessfully: true });
            })
            .catch(error => {
                toast.error("Failed to submit forgot password request.");
                setState({ ...state, isSubmitting: false });
            });
    };

    let modal = null;
    if (props.visible) {
        const footer = state.submittedSuccessfully ?
            <p>Please check your inbox for instructions.</p>
            :
            <>
                <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
                <EuiButton type="submit" fill isLoading={state.isSubmitting}>
                    Submit
                </EuiButton>
            </>


        modal = (
            <EuiModal onClose={closeModal}>
                <EuiForm component="form" onSubmit={handleSubmit(onSubmit)}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Forgot Password</EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>

                        <EuiFormRow label="Email">
                            <EuiFieldText name="Email"
                                icon="email"
                                inputRef={register({ required: true, minLength: 6 })}
                            />
                        </EuiFormRow>
                    </EuiModalBody>

                    <EuiModalFooter>
                        {footer}
                    </EuiModalFooter>
                </EuiForm>
            </EuiModal >

        );
    }

    return modal;
}

export default ForgotPasswordModal;
