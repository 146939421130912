import {
  EuiBreadcrumb,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
} from '@elastic/eui';

import React, { useEffect } from 'react';
import { setBreadcrumbs } from '../features/auth/authSlice';
import { store } from './store';

const Home: React.FC = () => {

  useEffect(() => {
    const breadcrumbs: EuiBreadcrumb[] = [
      {
        text: 'Home'
      }
    ];
    store.dispatch(setBreadcrumbs(breadcrumbs));
  }, []);

  return (
    <EuiPage>
      <EuiPageBody component="div">
        <EuiPageContent>
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle>
                <h2>Home</h2>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            {/* body */}
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
}

export default Home;
