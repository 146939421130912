import { EuiBreadcrumb } from "@elastic/eui";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, RootState } from "../../app/store";
import { IAuthModel } from "../../models/dtos";

interface AuthState {
    auth: IAuthModel;
    breadcrumbs: EuiBreadcrumb[];
    forbidden: boolean;
}

const initialState: AuthState = {
    auth: {
        email: "",
        full_name: "",
        access_token: "",
        refresh_token: "",
        role: ""
    },
    breadcrumbs: [],
    forbidden: false
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<IAuthModel>) => {
            state.auth = action.payload;
        },
        clearAuth: (state) => {
            state.auth = {
                email: "",
                full_name: "",
                access_token: "",
                refresh_token: "",
                role: ""
            };
        },
        setBreadcrumbs: (state, action: PayloadAction<EuiBreadcrumb[]>) => {
            state.breadcrumbs = action.payload;
        },
        setForbidden: (state, action: PayloadAction<boolean>) => {
            state.forbidden = action.payload;
        }
    },
});

export const { setAuth, clearAuth, setBreadcrumbs, setForbidden } = authSlice.actions;

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount: number): AppThunk => (dispatch) => {
//     setTimeout(() => {
//         dispatch(incrementByAmount(amount));
//     }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuth = (state: RootState) => state.auth.auth;
export const selectBreadcrumbs = (state: RootState) => state.auth.breadcrumbs;

export const authReducer = authSlice.reducer;
