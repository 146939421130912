import {
    EuiButtonEmpty,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiText,
    EuiPanel,
    EuiTitle,
} from '@elastic/eui';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../app/store';
import { EnrollmentDetailModalData } from '../../models/dtos';
import { setEnrollmentDetailModal } from './adminSlice';

const EnrollmentDetailModal: React.FC = () => {

    const modalData = useSelector((state: RootState) => state.admin.enrollmentDetailModal) as EnrollmentDetailModalData;

    const closeModal = () => {
        store.dispatch(setEnrollmentDetailModal({
            detail: undefined
        }));
    }

    const getModules = () => {
        const modules = modalData?.detail?.modules?.map((x, index) =>
            <>
                <EuiPanel paddingSize="s">
                    <EuiTitle size="s">
                        <h3>{x.moduleName}</h3>
                    </EuiTitle>

                    <dl key={index} className="eui-definitionListReverse">
                        <dt>Completed Date</dt>
                        <dd>{x.dateModuleCompleted ? dayjs(x.dateModuleCompleted).format("YYYY-MM-DD hh:mm A") : "N/A"}</dd>

                        <dt>Quiz Passed Date</dt>
                        <dd>{x.dateQuizPassed ? dayjs(x.dateQuizPassed).format("YYYY-MM-DD hh:mm A") : "N/A"}</dd>
                    </dl>
                </EuiPanel>
                <br />
            </>
        );
        return modules;
    }

    const getQuizzes = () => {
        const modules = modalData?.detail?.quizzes?.map((x, index) =>
            <tr key={index}>
                <td>{x.quizName}</td>
                <td>{dayjs(x.dateCreated).format("YYYY-MM-DD hh:mm A")}</td>
                <td>{x.passed ? "Passed" : "Failed"}</td>
                <td>{x.score}</td>
            </tr>
        );
        return modules;
    }

    let modal = null;
    if (modalData && modalData.detail) {
        modal = (
            <EuiModal onClose={closeModal} initialFocus="[name=startDate]">
                <EuiModalHeader>
                    <EuiModalHeaderTitle>Enrollment Detail</EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody style={{ "minHeight": "360px", "width": "700px" }}>
                    <EuiText>
                        {getModules()}
                        <h3>Quiz Attempts</h3>
                        <table className="custom-table">
                            <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Outcome</th>
                                <th>Score</th>
                            </tr>
                            <tbody>
                                {getQuizzes()}
                            </tbody>
                        </table>
                    </EuiText>

                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty onClick={closeModal}>Close</EuiButtonEmpty>
                </EuiModalFooter>
            </EuiModal>
        );
    }

    return modal;
}

export default EnrollmentDetailModal;
