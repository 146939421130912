import { EnhancedStore } from '@reduxjs/toolkit';
import axios from 'axios';
import { setForbidden } from '../features/auth/authSlice';
import { AppConfig } from '../models/dtos';
import { toast } from 'react-toastify';

declare const _appConfig: AppConfig;

export const axiosService = axios.create({
});

export function setupInterceptors(store: EnhancedStore) {
    axiosService.interceptors.request.use(
        config => {
            if (_appConfig?.accessToken) {
                config.headers["Authorization"] = "Bearer " + _appConfig.accessToken;
            }
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    axiosService.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        if (error.response) {
            console.log(error.response);
            switch (error.response.status) {
                case 401:
                    toast.dismiss();
                    toast.error("Please login again.");
                    break;
                case 403:
                    toast.dismiss();
                    toast.error("Access denied.");
                    store.dispatch(setForbidden(true));
                    break;
                default:
                    break;
            }
        }
        return Promise.reject(error);
    });
}
