import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router';

import { RootState } from './store';

interface IProps {
    path: string;
    exact?: any;
    component: any;
}

export const ProtectedRoute = (p: IProps) => {
    const { component, ...rest } = p;
    const { access_token } = useSelector(
        (state: RootState) => state.auth.auth
    );
    const marginTop = "4rem"; //6.5rem if show header menu
    return (
        <Route {...rest} render={props => (
            access_token
                ?
                <div className="main-content" style={{ "marginTop": marginTop }}>
                    <p.component {...props} />
                </div>
                : <Redirect to={{
                    pathname: "/login",
                    state: {
                        prevLocation: p.path,
                    }
                }} />
        )} />
    )
};
