import { combineReducers } from "@reduxjs/toolkit";

import { adminReducer } from "../features/admin/adminSlice";
import { authReducer } from "../features/auth/authSlice";
import { candidateReducer } from "../features/candidate/candidateSlice";

export const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  candidate: candidateReducer,
});
