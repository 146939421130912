import React, { Suspense } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState, store } from './app/store';

import './App.scss';
import Header from './app/Header';
import { ProtectedRoute } from './app/ProtectedRoute';
import Login from './features/auth/Login';
import Logout from './features/auth/Logout';
import Enrollments from './features/admin/Enrollments';
import Home from './app/Home';
import CandidateHome from './features/candidate/CandidateHome';
import { AppConfig } from './models/dtos';
import { setupInterceptors } from './app/interceptor';
import { ToastContainer } from 'react-toastify';
import ResetPassword from './features/auth/ResetPassword';

declare const _appConfig: AppConfig;

export const App: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);

  if (auth?.access_token) {
    _appConfig.accessToken = auth?.access_token;
  }

  setupInterceptors(store);

  return (
    <div>
      <Router>
        <Header></Header>
        <Suspense fallback={<div>Loading...</div>}>
          <div style={{ padding: "16px" }}>
            <Switch>
              <ProtectedRoute path="/" component={Home} exact={true} />
              <ProtectedRoute path="/enrollments" component={Enrollments} />
              <ProtectedRoute path="/candidate-home" component={CandidateHome} />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <Route path="/account/reset-password" component={ResetPassword} />
            </Switch>
          </div>
        </Suspense>
      </Router>
      <ToastContainer hideProgressBar />
    </div>
  );
}

